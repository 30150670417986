import React from "react";

const Error = () => {
    return (
        <div className="main" >
			<h1>Error</h1>
        </div>
    );
};

export default Error;